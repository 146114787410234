const menuItems = {
  items: [
    {
      id: 'mainmanu',
      title: 'メインメニュー',
      type: 'group',
      children: [
        {
          id: 'targetperson',
          title: 'モニター一覧',
          type: 'item',
          url: '/target_person',
          classes: 'nav-item',
          icon: 'feather icon-home',
          breadcrumbs: false
        },
        {
          id: 'usermanagement',
          title: 'アカウント管理',
          type: 'item',
          url: '/management',
          classes: 'nav-item',
          icon: 'feather icon-edit',
          breadcrumbs: false
        },
        {
          id: 'setinfo',
          title: 'デバイス管理',
          type: 'item',
          url: '/set_infos',
          classes: 'nav-item',
          icon: 'feather icon-info',
          breadcrumbs: false
        },
        {
          id: 'notification',
          title: '通知管理',
          type: 'item',
          url: '/notification',
          classes: 'nav-item',
          icon: 'feather icon-bell',
          breadcrumbs: false
        },
      ]
    }
  ]
};

export default menuItems;
