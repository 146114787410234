import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import Loader from "./components/Loader/Loader";
import AdminLayout from "./layouts/AdminLayout";

import GuestGuard from "./components/Auth/GuestGuard";
import AuthGuard from "./components/Auth/AuthGuard";

import { BASE_URL } from "./config/constant";

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<Loader />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route key={i} path={route.path} exact={route.exact} render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? renderRoutes(route.routes) : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    guard: GuestGuard,
    path: '/auth/signin-1',
    component: lazy(() => import('./views/auth/signin/SignIn1'))
  },
  {
    path: '*',
    layout: AdminLayout,
    guard: AuthGuard,
    routes: [
      // モニター一覧画面
      {
        exact: true,
        path: '/target_person',
        component: lazy(() => import('./views/dashboard/DashDefault'))
      },
      {
        exact: true,
        path: '/target_person/detail/:contractId/:dayOrWeek/:startDay',
        component: lazy(() => import('./views/targetPerson/Detail'))
      },
      // デイリーメッセージ登録画面
      {
        exact: true,
        path: '/daily_message/:contractId',
        component: lazy(() => import('./views/DailyMessage'))
      },

      // デバイス管理
      // デバイス検索画面
      {
        exact: true,
        path: '/set_infos',
        component: lazy(() => import('./views/SetInfos/DeviceList'))
      },
      // デバイス登録画面
      {
        exact: true,
        path:'/set_infos/detail',
        component: lazy(() => import('./views/SetInfos/Detail'))
      },
      // デバイス編集画面
      {
        exact: true,
        path:'/set_infos/update',
        component: lazy(() => import('./views/SetInfos/Detail/update'))
      },
      // デバイス紐づけ画面
      {
        exact: true,
        path:'/set_infos/link/:monitorId',
        component: lazy(() => import('./views/SetInfos/DeviceLink/'))
      },

      // 利用者管理Top
      {
        exact: true,
        path:'/management',
        component: lazy(() => import('./views/UserManagement/Top'))
      },
      // 申込者管理：申込者一覧画面
      {
        exact: true,
        path:'/management/contractList',
        component: lazy(() => import('./views/UserManagement/ApplicantList'))
      },
      // 申込者情報入力画面
      {
        exact: true,
        path:'/management/contract',
        component: lazy(() => import('./views/UserManagement/Contract'))
      },
      // 申込者情報照会画面
      {
        exact: true,
        path:'/management/contract/inquiry/:targetId',
        component: lazy(() => import('./views/UserInquiry/Contract'))
      },

      // モニター登録画面
      {
        exact: true,
        path:'/management/monitoring/detail/:applicantId',
        component: lazy(() => import('./views/UserManagement/Monitoring'))
      },
      // モニター情報照会画面
      {
        exact: true,
        path:'/management/monitoring/inquery/:monitoring_id',
        component: lazy(() => import('./views/UserInquiry/Monitoring'))
      },

      // 関係者一覧
      {
        exact: true,
        path:'/management/participant/',
        component: lazy(() => import('./views/UserManagement/ParticipantList'))
      },
      // 関係者情報照会
      {
        exact: true,
        path:'/management/participant/inquiry/:participant_id',
        component: lazy(() => import('./views/UserInquiry/Participant'))
      },
      // 関係者登録画面
      {
        exact: true,
        path:'/management/participant/detail/:monitorId',
        component: lazy(() => import('./views/UserManagement/Participant'))
      },
      // 関係者編集画面(登録画面は利用者側に移動するかもなので別建て)
      {
        exact: true,
        path:'/management/participant/edit',
        component: lazy(() => import('./views/UserManagement/Participant/edit'))
      },

      // 自治体一覧画面
      {
        exact: true,
        path:'/management/municipal',
        component: lazy(() => import('./views/UserManagement/Organ'))
      },
      // 自治体情報照会画面
      {
        exact: true,
        path:'/management/municipal/inquiry/:organ_id',
        component: lazy(() => import('./views/UserInquiry/Organ'))
      },
      // 自治体情報登録画面
      {
        exact: true,
        path:'/management/municipal/regist',
        component: lazy(() => import('./views/UserManagement/Municipal'))
      },

      // 管理者設定-TOP
      {
        exact: true,
        path:'/admin',
        component: lazy(() => import('./views/AdminManagement/Top'))
      },
      // 管理者情報照会画面
      {
        exact: true,
        path:'/admin/inquery/:admin_id',
        component: lazy(() => import('./views/UserInquiry/Admin'))
      },
      // 管理者設定-登録画面
      {
        exact: true,
        path:'/admin/regist',
        component: lazy(() => import('./views/AdminManagement/Regist'))
      },

      // 通知管理-TOP
      {
        exact: true,
        path:'/notification',
        component: lazy(() => import('./views/NotificationManagement/Top'))
      },
      // 通知管理-メール宛先エラー解除
      {
        exact: true,
        path:'/notification/mail/suppressionList',
        component: lazy(() => import('./views/NotificationManagement/SuppressionList'))
      },
      // 通知管理-LINE連携状況確認
      {
        exact: true,
        path:'/notification/line/accountLinkStatus',
        component: lazy(() => import('./views/NotificationManagement/LineAccountLinkStatus'))
      },
      // 通知管理-LINEテスト送信結果確認
      {
        exact: true,
        path:'/notification/line/sendTestMessageResult/:testTransId',
        component: lazy(() => import('./views/NotificationManagement/LineSendTestMessageResult'))
      },

      {
        path: '*',
        exact: true,
        component: () => <Redirect to={BASE_URL}/>
      }
    ]
  }
];

export default routes;
