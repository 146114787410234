import React, { createContext, useEffect, useReducer } from 'react';
import { ACCOUNT_INITIALISE, LOGIN, LOGOUT } from "../store/actions";
import axios from '../utils/axios';
import accountReducer from '../store/accountReducer';
import Loader from "../components/Loader/Loader";

import { useLocation } from "react-router";

const initialState = {
  isLoggedIn: false,
  isInitialised: false,
  isRegist: false,
  user: null
};

const JWTContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => { },
  /* regist: () => Promise.resolve() */
});

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);
  const location = useLocation();

  const login = async (email, password,retention) => {
    const response = await axios.post('/api/account/login', { email, password, retention });
    const { user } = response.data;
    dispatch({
      type: LOGIN,
      payload: {
        user
      }
    });
  };

  const logout =  async () => {
    await axios.post('/api/account/logout');
    dispatch({ type: LOGOUT });

    // レイアウトのクリア
    document.body.setAttribute('class', '');
    document.body.setAttribute('style', '');
    // タイトルの初期化
    document.title = "管理者ログイン | あんしんみまもり";
  };
  /* // 登録更新処理(1:処理内容,2:データ)
  const regist = async (mode,contents) =>{
    // 登録更新処理
    const response =await axios.post('/api/regist/data',{ mode, contents});
    // 処理結果(メッセージ取得)
    const {message} = response.data;
    dispatch({
      type: REGIST
    });
  } */

  useEffect(() => {
    const init = async () => {
      try {
        if (location.pathname === '/auth/signin-1') {
          dispatch({
            type: ACCOUNT_INITIALISE,
            payload: {
              isLoggedIn: false,
              user: null}
          });
          return;
        }

        const response = await axios.get('/api/account/me');
        const { user } = response.data;

        dispatch({
          type: ACCOUNT_INITIALISE,
          payload: {
            isLoggedIn: true,
            user
          }
        });

      } catch (err) {
        console.error(err);
        dispatch({
          type: ACCOUNT_INITIALISE,
          payload: {
            isLoggedIn: false,
            user: null
          }
        });
      }
    };

    init();
  }, []);

  if (!state.isInitialised) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider value={{ ...state, login, logout}}>
      {children}
    </JWTContext.Provider>
  );
};

export default JWTContext;
